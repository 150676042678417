.dashboardHeader { 
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 7.5%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.171);
}

.userInfoHeader {
    width: 280px;
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
}

.hamburguer {
    display: none;
}

.bellIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    position: relative;
}

.bellIcon:hover {
    cursor: pointer;
}

.dot {
    position: absolute;
    top: 10px;
    left: 26px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: crimson;
}

.userPicture {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.profilePic {
    width: 45px
}

.hamburguerMenu {
    width: 250px;
    height: 100vh;
    background-color: #152522;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding-top: 15px;
    color: white;
}

.hamburguerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 35px;
}

.hamburguerHeader i {
    font-size: 1.5em;
}

.hamburguerHeader h1 {
    font-size: 1.8em;
}

.hamburguerMenu p {
    width: 90%;
    margin: auto;
    padding: 15px;
    border-radius: 15px;
    background-color: #c7ef4e1c;
}

.hamburguerMenu p:hover {
    cursor: pointer;
}

.hamburguerMenu footer {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    font-size: 1em;
    align-items: center;
    justify-content: center;
    background-color: #C6EF4E;
    color: #152522;
}

@media (width <= 570px) {
    .dashboardHeader h1 {
        font-size: 1.5em;
    }
}

@media (width <= 520px) {

    .headerH1 {
        display: none;
    }

    .bellIcon {
        display: none;
    }

    .hamburguer {
        display: flex;
        font-size: 2em;
    }

    .userInfoHeader {
        width: 40%;
        justify-content: space-between;
    }

}