.main h1 {
    font-size: 25px;
}

.main p {
     font-size: 25px;
}

.main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    background-color: #152522;
    padding-left: 30px;
    padding-right: 30px;
}

.blockOne {
    display: flex;
    align-items: center;
    padding: 25px;
    width: 49.5%;
    height: 70px;
    background-color: #E5EDD9;
    border-radius: 0px 0px 5px 5px;
    color: #152522;
}

.blockTwo {
    width: 49.5%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px solid #F6A8FE;
    border-top: #152522;
    border-radius: 0 0 5px 5px ;
    color: #E5EDD9;
    justify-content: space-between;
    padding: 20px;
}

.blockTwo h1 {
    font-size: 1.2em;
}

.blockTwoTitle {
    padding-right: 10px;
}

.blockTwo .button {
    width: 110px;
    height: 50px;
    border-radius: 5px;
    background-color: #F6A8FE;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #152522;
}

#dashboard {
    font-size: 0.9em;
}

@media (width <= 716px) {
    .main {
        padding-left: 0;
        padding-right: 0;
    }

    .blockTwoTitle {
        display: none;
    }

    .blockTwo {
        padding: 0;
        justify-content: center;
    }

    .blockTwo .button {
        width: 80%;
        height: 40px;
    }
}
