.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    width: 70px;
    height: 920px;
    background-color: #000000;
    border-radius: 25px;
    gap: 20px;
    z-index: 999;
}

.li {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.li div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.li div i {
    color: white;
    padding: 10px;
    font-size: 1.5em;
    border-radius: 50%;
}

.li div i:hover {
    background-color: rgba(255, 255, 255, 0.274);
}

footer {
    display: flex;
    height: 50px;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    color: white;
}

footer i:hover {
    cursor: pointer;
}

.luminyLogo {
    width: 20px;
    filter: invert();
    padding-top: 20px;
}


@media (width <= 1900px) and (width > 1100px) {
    .main {
        height: 830px;
    }
}

@media (width <= 1100px) {
    .main {
        display: none;
    }
}