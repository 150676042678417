.main {
    width: 100%;
    height: 530px;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 20px;
    background: url('../../img/LearnMore.png');
    background-position: 10% 80%;
    line-height: 60px;
    font-size: 1.3em;
}


#button {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: white;
    color: rgb(0, 0, 0);
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-size: 0.7em;
    
}

#button:hover {
    cursor: pointer;
}

.mark {
    border: 2px solid black;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    width: 180px;
    margin-top: 20px;
    margin-bottom: 200px;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -310px;
        margin-top: -85px;
        transform: scale(0.8);
        height: 594px;
    }
}


@media (width <= 1100px) {
    .main {
        background-size: cover;
        border-radius: 10px 10px 0 0;
    }
}