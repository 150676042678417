.main {
    width: 790px;
    height: 380px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 20px;
}

.main h1 {
    font-size: 1.2em;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.headerStart {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.headerStart p {
    font-size: 0.8em;
    color: rgba(58, 58, 58, 0.384);
}

.headerButton {
    padding: 10px;
    background-color: rgba(204, 204, 204, 0.384);
    border-radius: 20px;
    font-size: 0.8em;
    color: rgba(58, 58, 58, 0.384);
}

.headerButton:hover {
    cursor: pointer;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -85px;
        margin-top: -125px;
        transform: scale(0.8);
        width: 720px;
        height: 450px;
    }
}


@media (width <= 1100px) {
    .main {
        width: 100%;
        overflow-x: scroll;
        overflow-x: hidden;
    }

}