h1 {
    font-size: 2.5em;
}

p {
    font-size: 1.2em;
}

.main {
    display: flex;
    width: auto;
    height: 100vh;
    padding: 15px;
    background: url("../../img/dashboardBackground.png");
    background-size: contain;
}

.dashboard {
    display: flex;
    width: 2000px;
    gap: 20px;
    padding-top: 80px;
    padding-left: 40px;
}

.section1 {
    display: flex;
    width: 1250px;
    height: 820px;
    gap: 10px;
    flex-wrap: wrap;
}

.section2 {
    width: 500px;
    height: 840px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}


@media (width <=1900px) and (width >1100px) {
    .dashboard {
        height: 600px;
        transform: scaleX(1.2);
        padding-left: 200px;
    }
}

@media (width <=1800px) and (width >1100px) {
    .dashboard {

        transform: scaleX(1.1);
        padding-left: 140px;
    }
}

@media (width <=1690px) and (width >1100px) {
    .dashboard {

        transform: scaleX(1.05);
        padding-left: 100px;
    }
}

@media (width <=1615px) and (width >1100px) {
    .dashboard {
        transform: scaleX(1.02);
        padding-left: 50px;
    }
}

@media (width <=1540px) and (width >1100px) {
    .dashboard {
        transform: scaleX(0.9);
        margin-left: -100px;
    }
}

@media (width <=1380px) and (width >1100px) {

    .dashboard {
        transform: scale(1);
        padding-top: 80px;
        padding-left: 40px;
        margin: 0;
    }

    .section2 {
        display: none;
    }
}

@media (width <= 1100px) {

    .main {
        padding: 0;
        height: auto;
    }

    .dashboard {
        padding-left: 0;
        width: 100%;
        padding-top: 100px;
        flex-direction: column;
    }

    .section1,
    .section2 {
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto;
        width: 100%;
    }
}