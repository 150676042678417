

.section1Wide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    position: relative;
    background-color: #152522;
    font-size: 5em;
    color: #F8A9FF;
    line-height: 170px;
}

.section1 {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    position: relative;
    background-color: #152522;
    font-size: 5em;
    color: #F8A9FF;
    line-height: 170px;
}

.section1 h1, .section1Wide h1 {
    font-family: Stapel;
    padding-left: 40px;
}

.ball {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 700px;
    height: 220px;
    border-radius: 100%;
    background-color: #BDDE86;
    mix-blend-mode: color;
}

.eyes {
    position: absolute;
    width: 190px;
    height: 85px;
    top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.eyeBall {
    background-color: #BDDE86;
    width: 50%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pupil {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    height: 50px;
    width: 70px;
    background: #BDDE86;
    border-radius: 100px;
}

.pupil::after {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #152522;
    border-radius: 50%;
    content: " ";
    margin: -15px;
    animation: eyes 2s forwards;
}

.section2 {
    display: flex;
    height: 900px;
    padding-top: 40px;
    background-color: #152522;
    padding: 0px 20px;
    justify-content: space-between;
    position: relative;
}


.left, .right {
    width: 920px;
    height: 850px;
    background-color: #2B3935;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding-top: 105px;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 20px;
}

.warning {
    position: absolute;
    top: 40px;
    left: 30px;
    display: flex;
    width: 400px;
    height: 50px;
    align-items: center;
    background-color: #606B69;
    border-radius: 5px;
    font-size: 0.55em;
    gap: 15px;
    padding-left: 15px;
    color: #BDDE86;
}

.warning i {
    font-size: 3em;
}

.imgContainer1 {
    width: 600px;
    height: 500px;
    background-color: beige;
    border-radius: 5px;
    background: url('../../img/Print1.svg');
    background-size: cover;
}


.imgContainer2 {
    width: 600px;
    height: 500px;
    background-color: beige;
    border-radius: 5px;
    background: url('../../img/Print2.svg');
    background-size: cover;
}

.info {
    width: 900px;
    height: 200px;
    background-color: #E0FEAC;
    ;
    border-radius: 10px;
    padding: 20px 20px 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
}

.info div {
    display: flex;
    align-items: center;
}

.info i {
    font-size: 2em;
    color: #F8A9FF;
}

.info div h1 {
    margin-right: 15px;
    color: #132719;
}

.info p {
    color: #39522D;
    width: 600px;
    font-size: 1.5em;
}


#addToCart:hover {
    cursor: pointer;
}

.section3 {
    height: 950px;
    background-color: white;
}

.marquee {
    background-color: #F8A9FF;
    display: flex;
    padding: 5px;
    height: 80px;
}

#innerDiv {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 4px solid #132719;
    border-bottom: 4px solid #132719;
    gap: 25px;
    font-size: 2em;
    padding: 10px;
}

#innerDiv p {
    padding-top: 5px;
}

#innerDiv img {
    width: 20px;
}

.colorBanner {
    width: 100%;
    height: 100px;
    background-color: #152522;
}

.section3Content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    gap: 200px;
}

.badge {
    width: 400px;
    animation: rotate 12s linear infinite;
}


.rightBlock {
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.description {
    font-size: 2.5em;
}

.boldSpan {
    font-family: OmnyItalic;
}

.button {
    width: 250px;
    display: flex;
    padding: 10px;
    background-color: #F8A9FF;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 0.6em;
}

.button i {
    font-size: 2.5em;
    padding-top: 5px;
}



.section4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
    height: 500px;
    gap: 30px;
    background-color: #152522;
    position: relative;
    padding: 30px;
}



.leftText {
    font-size: 6em;
    color: #F8A9FF;
    line-height: 90px;
    padding-top: 130px;
    font-family: OmnyBold;
}

.entrar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    width: 450px;
    height: 60px;
    border: 2px solid #E0FEAC;
    margin-bottom: 180px;
    color: #E0FEAC;
}

.calltoActionText {
    margin-right: 15px;
}

.entrarLink {
    color: #E0FEAC;
}

.entrar h1 {
    font-size: 2em;
    text-decoration: underline;
}

#footer {
    background-color: #F8A9FF;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#footer p {
    font-size: 0.8em;
    color: #ffffff;
}



@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes eyes {

    0% {
        width: 40px;
        height: 40px;
    }

    50% {
        width: 40px;
        height: 40px;
    }

    400%{
        width: 60px;
        height: 60px;
    }
    
}

@media (width <= 1800px) {

    .section2 {
        justify-content: space-around;
        gap: 20px;
    }

    .left, .right {
        width: 800px;
    }

    .info {
        width: 90%;
        font-size: 0.9em;
    }

    .info p {
        width: 90%;
    }

}


@media (width <= 1690px) {
    .section1Wide {
        font-size: 4em;
        line-height: 150px;
    }

    .ball {
        width: 600px;
        height: 200px;
    }

    .section3Content {
        gap: 150px;
    }
}


@media (width <= 1390px) {
    .section1Wide {
        font-size: 3em;
        line-height: 120px;
    }

    .ball {
        width: 500px;
        height: 150px;
    }

    .info {
        font-size: 0.8em;
    }
}


@media (width <= 1280px) {

    .section1Wide {
        display: none;
    }

    .section1 {
        display: flex;
        font-size: 4em;
        text-align: center;
        line-height: 130px;
        height: 700px;
    }

    .ball {
        top: 150px;
        left: 0;
        right: 0;
        margin: auto;
        width: 450px;
        height: 310px;
    }

    .eyes {
        top: -15px;
    }

    .section2 {
        flex-direction: column;
        justify-content: start;
        padding: 20px;
        height: auto;
        gap: 100px;
    }

    .left, .right {
        width: 100%;
        padding: 10px;
    }

    .warning {
        width: 101%;
        left: -5px;
        top: -70px;
        font-size: 0.4em;    
    }

    .imgContainer1, .imgContainer2 {
        width: 90%;
    }

    .info {
        width: 90%;
        font-size: 0.8em;
    }

    .info p {
        width: 90%;
        font-size: 2em;
    }
   
    .section3Content {
        padding: 0;
        flex-direction: column;
        padding-top: 110px;
        gap: 30px;
        justify-content: center;
    }

    .rightBlock {
        width: 90%;
        gap: 30px;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }

    .description {
        font-size: 1.5em;
    }

    .badge {
        width: 300px;
    }

    .section4 {
        padding: 0;
        width: 100%;
    }

    .leftText {
        font-size: 3em;
        line-height: 50px;
        padding-top: 170px;
    }

    .entrar {
        width: 250px;
        position: absolute;
        right: 0;
        font-size: 0.7em;
    }

    #footer {
        height: 50px;
        font-size: 1em;
    }

}

@media (width <= 760px) {
    .section1 {
        font-size: 3em;
        line-height: 90px;
        height: 650px;
    }

    .ball {
        width: 450px;
        height: 250px;
        top: 180px;
    }
}

@media (width <= 700px) {
    .info {
        font-size: 0.6em;
    }
}

@media (width <= 600px) {
    .imgContainer2 {
        background-position: 55% 30%;
    }
}

@media (width <= 540px) {
    .info {
        font-size: 0.5em;
    }
}

@media (width <= 590px) {
    .section1 {
        font-size: 2em;
        line-height: 65px;
    }

    .section2 {
        height: 1900px;
    }

    .section3 {
        height: 1100px;
    }

    .ball {
        width: 80%;
        height: 180px;
        top: 230px;
    }

    .eyes {
        top: 50px;
    }
}