.main {
    width: 450px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.178);
}

.line {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 75px;
    height: 2px;
    background-color: #C6EF4E;
}

.cards {
    display: flex;
    width: 100%;
    height: 380px;
    padding: 20px;
    position: relative;
    justify-content: center;
}

.frontCard {
    position: absolute;
    bottom: 110px;
    width: 408px;
    height: 210px;
    border-radius: 15px;
    background-color: #C6EF4E;
    padding: 25px;
    z-index: 1;
    
}

.backCardPlat {
    width: 350px;
    height: 100px;
    position: absolute;
    background-color: #E6E6DC;
    border-radius: 15px;
    padding-top: 5px;

}

.backCardPlat header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 8px 20px;
    font-size: 0.8em;
}

.backCardPlat:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.cardHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cardHeader img {
    width: 15%;
}

.value {
    font-size: 1.5em;
    margin-bottom: 40px;
}

.info {
    width: 105%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spireDate {
    display: flex;
    gap: 25px;
}

.info img {
    width: 25%;
}

#backcardOuro {
    background-color: #C6EF4E;
}

#frontCardPlat {
    background-color: #E6E6DC;
}

#blasterCard {
    width: 15%;
}

.bottomInfo {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 0;
    gap: 10px;
}

.cardInfo {
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
}

.cardInfo div {
    display: flex;
    font-size: 0.8em;
    gap: 5px;
    padding: 10px;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -22px;
        margin-top: -90px;
        transform: scale(0.95);
    }
}


@media (width <= 1100px) {
    .main {
        width: 100%;
    }

    .cards {
        justify-content: center;
    }
    
}

@media (width <= 450px) {
    .frontCard {
        width: 90%;
    }

    .backCardPlat {
        width: 80%;
    }

    #moeda  {
        display: none;
    }

    .cardInfo {
        width: 100%;
    }
}