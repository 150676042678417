.main {
    width: 620px;
    height: 450px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.178);
}

.header {
    width: 100%;
    display: flex;
    padding: 20px;
    z-index: 1;
    position: relative;
    gap: 50px;
}

#delete {
  position: absolute;
  top: 22px;
  left: 215px;
}

#delete:hover {
    cursor: pointer;
}

.delete i:hover {
    font-size: 15px;
    cursor: pointer;
}

#selectedCard {
    font-size: 15px;
    padding-top: 5px;  
}

#transactionFormButton {
    background-color: black;
    color: white;
    width: 240px;
    display: flex;
    flex-direction: column;
    z-index: 99;
    position: absolute;
    right: 20px;
    top: 12px;
}

.transactionForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.transactionForm button {
    width: 200px;
    height: 50px;
    display: flex;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}


.select {
    width: 160px;
    height: 50px;
}

.select:focus {
    outline: none;
}

.transactionsCard {
    display: flex;
    justify-content: space-between; 
    width: 90%;
    position: relative;
    margin-bottom: 8px;
    margin-left: 15px;
    transform: scale(0.9);

}

.leftDiv {
    display: flex;
    gap: 10px;
    width: 210px;
}

.leftDiv img {
    border-radius: 15px;
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.infoDayHour{
    display: flex;
    width: 130px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    position: relative;
}

.dot {
    position: absolute;
    left: 58%;
    top: -20%;
}

.rightDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -70px;
        margin-top: -40px;
        transform: scale(0.8);
    }
}


@media (width <= 1100px) {
    .main {
        width: 100%;
        height: 550px;
    }

    #transactionFormButton {
        left: 50%;
        top: 800%;
        transform: translate(-50%, -50%);
    }

    #selectedCard {
        padding-right: 20px;
    }

    .transactionsCard {
        width: 90%;
        transform: scale(1);
        margin-bottom: 15px;
    }
}
