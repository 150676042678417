.main {
    width: 100%;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profilePic {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
    margin-bottom: 15px;
}

.profilePic img {
    width: 15%;
    padding: 3px;
}

.profilePic img:hover {
    border: 1px solid black;
    border-radius: 50%;
}

#plus {
    font-size: 2em;
    border-radius: 50%;
    border: 1px dashed black;
    padding: 15px;
}

.button {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: black;
    color: white;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.button:hover {
    cursor: pointer;
}

.button2 {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px;
    background-color: #C6EF4E;
    color: white;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    transition: 1s;
}

.button2 i {
    font-size: 2.2em;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -310px;
        margin-top: -25px;
        transform: scale(0.8);
    }
}

@media (width <= 1100px) {
    .main {
        width: 100%;
        height: 380px;
        
    }
}