.main {
    width: 620px;
    height: 450px;
    background-color: white;
    border-radius: 20px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.178);
}

.main header {
    height: 60px;
    display: flex;
    padding: 22px;
    justify-content: space-between;
    align-items: center;
}

.info {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 10px;
}

.ball {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #D2FF4C;
}

.teste {
    height: 350px !important;
    padding-bottom: 15px;
}

@media (width <= 1900px) and (width > 1100px) {
    .main {
        margin-left: -120px;
        margin-top: -40px;
        transform: scale(0.8);
    }
}
 

@media (width <= 1100px) {
    .main {
        width: 100%;
    }
}