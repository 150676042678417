* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: bolder;
}


a {
  text-decoration: none;
  color: black;
}

@font-face {
  font-family: OmnyBold;
  src: url(fonts/BROmny-Bold-BF65557eb88a83f.otf);
}

@font-face {
  font-family: Omny;
  src: url(fonts/BROmny-Light-BF65557eb87f5b9.otf);
}

@font-face {
  font-family: OmnyItalic;
  src: url(fonts/BROmny-BoldItalic-BF65557eb89deb2.otf);
}

@font-face {
  font-family: Stapel;
  src: url(fonts/stapel-bold.otf);
}

html {
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

body {
  position: relative;
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

section {
  scroll-snap-align: end;
}

body {
  font-family: Omny;
}

html::-webkit-scrollbar {
  width: 5px;
  background-color: #152522;
}


html::-webkit-scrollbar-thumb {
  background: #E0FEAC;
  border-radius: 15px;
  border-right: none;
}



html::-webkit-scrollbar-thumb:active {
  background-color: #F8A9FF;
}

